.MuiGrid-container {
	margin: 0 -8px;
}

.MuiGrid-spacing-xs-6 {
	width: calc(100% + 16px);
	margin: -8px;
}

.winningCard {
	animation: rotate 0.7s ease-in-out both;
}

body a {
	text-decoration: none;
}

.mail-link {
	color: #fff;
	font-family: 'Barlow Semi Condensed';
}

.hoverlink {
	color: #000;
}



.hoverlink:hover {
	opacity: 0.7;
	color: #000;
}

.redlink {
	background-color:#f54b4b;
	color: #fff;
	padding: 5px;
}

.redlink:hover {
	opacity: 0.7;
	/*color:#f54b4b;*/
}

.tavoitelista {
	font-family: 'Barlow Semi Condensed';	
}

.red {
	fill: #f54b4b;
	height: 20px;
}

.videoframe {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%;
}

.videocontent {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
  }

/* h1 {
	font-family: 'Barlow Semi Condensed';
	font-weight: '800';
	text-transform: 'uppercase';
} */

@keyframes rotate {
	0% {
		transform: rotateX(0deg) translate3d(0, 0, 0);
	}
	25% {
		transform: rotateX(-40deg) translate3d(0, 0, 0);
	}
	50% {
		transform: rotateX(20deg) translate3d(0, 0, 0);
	}
	75% {
		transform: rotateX(-10deg) translate3d(0, 0, 0);
	}
	100% {
		transform: rotateX(0deg) translate3d(0, 0, 0);
	}
}
